import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faChessPawn } from '@fortawesome/free-regular-svg-icons/faChessPawn';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://github.com/bhalavat-nisarg',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.linkedin.com/in/nisarg-bhalavat',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'mailto:nisargbhalavat.nmims@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
  {
    link: 'https://www.chess.com/member/nisargb9',
    label: 'Chess',
    icon: faChessPawn,
  },
];

export default data;
