import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env;

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Nisarg Bhalavat</h2>
        <p><a href="mailto:hi@nisargbhalavat.com">hi@nisargbhalavat.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Nisarg. I am a Software Developer at <a href="https://www.scu.edu/engineering/labs--research/labs/frugal-innovation-hub/">SCU Frugal Innovation Hub</a> and,
        a <a href="https://www.scu.edu/engineering/academic-programs/department-of-computer-engineering/">Santa Clara University CSE</a> graduate. Previously,
        I have worked at <a href="https://kpmg.com/xx/en/home.html">KPMG</a>, and early-age startups like <a href="https://infiniteoptions.com/">Infinite Options</a>,&nbsp;
        <a href="https://www.easilydone.in/">EasilyDone Technologies</a>, and <a href="https://www.theworks.in/">The Works Media</a>.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Nisarg Bhalavat <Link to="/"> nisargbhalavat.com</Link> .</p>
    </section>
  </section>
);

export default SideBar;
